.TagGroupEllipsis_tagGroup__fpz1ed0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}
.TagGroupEllipsis_dotDivider__fpz1ed1 {
  font-weight: var(--seed-static-font-weight-bold);
  font-size: 0.625rem;
}
.TagGroupEllipsis_dotDivider__fpz1ed1::after {
  content: "\B7";
}
.TagGroupEllipsis_dotDivider_size_small__fpz1ed2 {
  margin: 0 0.2188rem;
}
.TagGroupEllipsis_dotDivider_size_medium__fpz1ed3 {
  margin: 0 0.25rem;
}
.TagGroupEllipsis_dotDivider_color_gray600__fpz1ed4 {
  color: var(--seed-scale-color-gray-600);
}
.TagGroupEllipsis_tagGroupContainer_subGroup_false__fpz1ed6 {
  min-width: 0;
}