.CardCommunity_mainContainer__ips4d00 {
  display: flex;
  flex-direction: column;
}
.CardCommunity_mainContentContainer__ips4d01 {
  display: flex;
  flex-direction: column;
  margin-right: 0.625rem;
}
.CardCommunity_mainContentWithImageContainer__ips4d02 {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}
.CardCommunity_subContainer__ips4d03 {
  display: flex;
  flex-direction: column;
  margin-top: 0.875rem;
}
.CardCommunity_commentContainer__ips4d04 {
  font-size: var(--seed-semantic-typography-body-m2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: var(--seed-semantic-color-paper-contents);
}
.CardCommunity_badgeCategory__ips4d05 {
  font-size: var(--seed-semantic-typography-label5-regular-font-size);
  font-weight: var(--seed-semantic-typography-label5-regular-font-weight);
  line-height: var(--seed-semantic-typography-label5-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-regular-letter-spacing);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: var(--seed-scale-color-gray-100);
  color: var(--seed-scale-color-gray-700);
  margin-right: auto;
}
.CardCommunity_titleContainer__ips4d06 {
  font-size: var(--seed-semantic-typography-body-m1-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m1-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m1-regular-letter-spacing);
  color: var(--seed-scale-color-gray-900);
}
.CardCommunity_descriptionContainer__ips4d07 {
  margin-top: 0.25rem;
  font-size: var(--seed-semantic-typography-body-m2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
}
.CardCommunity_imageContainer__ips4d08 {
  flex: 0 0 auto;
  width: 4.5rem;
  height: 4.5rem;
  background-color: var(--seed-semantic-color-paper-background);
  border-radius: 0.375rem;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}
.CardCommunity_imageContainer__ips4d08 .CardCommunity_image__ips4d09 {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  border-radius: 0.375rem;
}
.CardCommunity_image__ips4d09[src=""] {
  display: none;
}
.CardCommunity_imageCount__ips4d0a {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--seed-semantic-color-overlay-dim);
  padding: 0 0.375rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 0.375rem;
  font-size: var(--seed-semantic-typography-label5-regular-font-size);
  font-weight: var(--seed-semantic-typography-label5-regular-font-weight);
  line-height: var(--seed-semantic-typography-label5-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-regular-letter-spacing);
  color: var(--seed-static-color-static-white);
}
.CardCommunity_additionalInfoContainer__ips4d0b {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  align-items: center;
}
.CardCommunity_userReactionsContainer__ips4d0d {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}
.CardCommunity_userReactionContainer__ips4d0e {
  display: flex;
  flex-direction: row;
  margin-right: 0.25rem;
}
.CardCommunity_userReactionContainer__ips4d0e:last-child {
  margin-right: 0;
}
.CardCommunity_iconContainer__ips4d0f {
  width: 1.125rem;
  height: 1.125rem;
}
.CardCommunity_userReactionThumbUpCount__ips4d0g {
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
  margin-left: 0.125rem;
}
.CardCommunity_userReactionReplyCount__ips4d0h {
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
  margin-left: 0.125rem;
}
.CardCommunity_lineClampWithEllipsis__ips4d0i {
  display: -webkit-box;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.CardCommunity_lineClampWithEllipsis_lineClamp_1__ips4d0j {
  -webkit-line-clamp: 1;
}
.CardCommunity_lineClampWithEllipsis_lineClamp_2__ips4d0k {
  -webkit-line-clamp: 2;
}