.SearchInputCategoryLabel_CategoryContainer__18f8lcs0 {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}
.SearchInputCategoryLabel_CategoryClose__18f8lcs1 {
  display: inherit;
  margin: -0.375rem -0.344rem -0.375rem 0;
  padding: 0.375rem 0.344rem;
  cursor: pointer;
  color: var(--seed-scale-color-gray-900);
}
.SearchInputCategoryLabel_Category__18f8lcs2 {
  display: flex;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 0.25rem;
  border: 1px solid var(--seed-scale-color-gray-300);
  border-radius: 1rem;
  background-color: var(--seed-semantic-color-paper-default);
}
.SearchInputCategoryLabel_CategoryText__18f8lcs3 {
  color: var(--seed-scale-color-gray-700);
  font-size: 0.688rem;
  font-weight: bold;
  line-height: 145%;
  max-width: 4.7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}