.Appearance_initButton__k5sn0r0 {
  font-family: inherit;
  border: none;
  background: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.Appearance_initParagraph__k5sn0r1 {
  margin: 0;
}