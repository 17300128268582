.CardCommerce_userReactionCaptions__wkcp250 {
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
}
.CardCommerce_caption__wkcp251 {
  color: var(--seed-scale-color-gray-700);
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
  margin-right: 0.2969rem;
  line-height: 100%;
}
.CardCommerce_caption__wkcp251:last-child {
  margin-right: 0;
}
.CardCommerce_soldOut__wkcp252 {
  font-size: 0.75rem;
  text-align: center;
  padding: 0.25rem 0.563rem;
  background-color: var(--seed-static-color-static-black-alpha-500);
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  color: var(--seed-static-color-static-white);
  -webkit-text-fill-color:  var(--seed-static-color-static-white);
}
.CardCommerce_soldOutOverlay__wkcp253 {
  background-color: var(--seed-scale-color-gray-alpha-50);
  width: 6.75rem;
  height: 6.75rem;
  position: absolute;
  top: 0;
  left: 0;
}
.CardCommerce_flags__wkcp254 {
  margin-top: 0.375rem;
  display: flex;
}
.CardCommerce_flag__wkcp255:not(:last-child) {
  margin-right: 0.188rem;
}
.CardCommerce_price__wkcp256 {
  line-height: 135%;
  margin-top: 0.125rem;
  font-size: 1rem;
}
.CardCommerce_amount__wkcp257 {
  font-weight: bold;
}
.CardCommerce_discount__wkcp258 {
  font-weight: bold;
  margin-right: 0.25rem;
  color: var(--seed-semantic-color-primary);
  -webkit-text-fill-color: var(--seed-semantic-color-primary);
}
.CardCommerce_container__wkcp259 {
  display: flex;
  letter-spacing: -0.02rem;
  text-decoration: none;
  font-style: normal;
  color: var(--seed-scale-color-gray-900);
}
.CardCommerce_imageContainer__wkcp25a {
  flex: 0 0 auto;
  width: 6.75rem;
  height: 6.75rem;
  background-color: var(--seed-semantic-color-paper-background);
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.375rem;
}
.CardCommerce_imageContainer__wkcp25a .CardCommerce_image__wkcp25b {
  width: 6.75rem;
  height: 6.75rem;
  object-fit: cover;
  border-radius: 0.375rem;
}
.CardCommerce_image__wkcp25b[src=""] {
  display: none;
}
.CardCommerce_main__wkcp25c {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 0;
}
.CardCommerce_title__wkcp25d {
  line-height: 135%;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--seed-scale-color-gray-900);
}
.CardCommerce_captionIcon__wkcp25e {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.1719rem;
  color: var(--seed-scale-color-gray-600);
}
.CardCommerce_infoContainer__wkcp25f {
  margin-top: 0.125rem;
}