.Shortcut_Container__2oo4d80 {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0.469rem 0.875rem;
  background-color: var(--seed-semantic-color-paper-default);
  border: 1px solid var(--seed-semantic-color-divider-3);
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  -webkit-tap-highlight-color: transparent;
}
.Shortcut_Container__2oo4d80:active {
  background-color: var(--seed-scale-color-gray-100);
  transition: background-color 0s;
}
.Shortcut_Name__2oo4d81 {
  font-size: 0.875rem;
  color: var(--seed-scale-color-gray-900);
}
.Shortcut_RightArrow__2oo4d82 {
  margin-left: 0.656rem;
}
.Shortcut_RightArrowPath__2oo4d83 {
  fill: var(--seed-scale-color-gray-900);
}