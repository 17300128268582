@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

.seed-snackbar-region {
  padding: var(--seed-snackbar-region-padding-x);
}

.seed-snackbar {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: var(--seed-snackbar-max-width);

  background: var(--seed-scale-color-gray-900);
  color: var(--seed-scale-color-gray-00);

  border-radius: var(--seed-snackbar-border-radius);

  padding: var(--seed-snackbar-padding);

  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-name: fadeIn;
}

.seed-snackbar:not([data-open]) {
  animation-duration: var(--remove-delay);
  animation-name: fadeOut;
}

.seed-snackbar:focus[data-focus-visible] {
  outline: 2px solid var(--seed-scale-color-blue-600);
}

.seed-snackbar [data-part="icon"] {
  flex-shrink: 0;

  width: var(--seed-snackbar-icon-size);
  height: var(--seed-snackbar-icon-size);
  margin-right: var(--seed-snackbar-icon-margin-end);
}

.seed-snackbar[data-type="success"] [data-part="icon"] {
  color: var(--seed-snackbar-success-icon-color);
}

.seed-snackbar[data-type="warning"] [data-part="icon"] {
  color: var(--seed-snackbar-warning-icon-color);
}

.seed-snackbar [data-part="title"] {
  flex-grow: 1;
  margin: 0;
}

.seed-snackbar-action-trigger {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: unset;
  text-decoration: none;

  outline: none;

  flex-shrink: 0;

  padding: var(--seed-snackbar-action-padding);
  margin-inline-start: var(--seed-snackbar-action-margin);
  color: var(--seed-semantic-color-primary);
}
