.IconContainerRecentSearch_circularBorder__j21rw70 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  border: 1px solid var(--seed-semantic-color-divider-1);
  border-radius: 1.75rem;
  box-sizing: border-box;
  overflow: hidden;
}