.CardUser_container__v16avm0 {
  display: flex;
  align-items: center;
  color: var(--seed-scale-color-gray-900);
}
.CardUser_imageContainer__v16avm1 {
  flex: 0 0 auto;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.25rem;
  margin-right: 1rem;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
          mask-image: -webkit-radial-gradient(white, black);
}
.CardUser_imageContainer__v16avm1:after {
  content: "";
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.25rem;
  box-shadow: inset 0 0 0 1px var(--seed-scale-color-gray-alpha-50);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.CardUser_imageContainer__v16avm1 .CardUser_image__v16avm2 {
  width: 2.25rem;
  height: 2.25rem;
  object-fit: cover;
}
.CardUser_image__v16avm2[src=""] {
  display: none;
}
.CardUser_right__v16avm3 {
  flex: 1;
}
.CardUser_rightTop__v16avm4 {
  display: flex;
  align-items: center;
}
.CardUser_name__v16avm5 {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--seed-scale-color-gray-900);
  margin-right: 0.25rem;
}
.CardUser_externalId__v16avm6 {
  font-size: 0.8125rem;
  color: var(--seed-scale-color-gray-900);
}
.CardUser_regionName__v16avm7 {
  font-size: 0.8125rem;
  color: var(--seed-scale-color-gray-600);
}