.CardPlaceItem_listItem__1jfpuf10 {
  padding: 1.125rem 1rem 0 1rem;
}
.CardPlaceItem_listItem__1jfpuf10 > :first-child {
  padding-bottom: 1.125rem;
  box-shadow: 0 1px 0 0 var(--seed-scale-color-gray-alpha-50);
}
.CardPlaceItem_listItem__1jfpuf10:last-child > :first-child {
  box-shadow: none;
}
.CardPlaceItem_container__1jfpuf11 {
  width: 100%;
  height: 100%;
  padding: 1.125rem 1rem 1.125rem 1rem;
}