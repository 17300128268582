.seed-help-bubble[data-part="backdrop"] {
  position: fixed;
  inset: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background: var(--seed-help-bubble-backdrop-background);
}

.seed-help-bubble [data-part="arrow"] {
  --arrow-size: var(--seed-help-bubble-arrow-size);
  --arrow-background: var(--seed-help-bubble-background);
}

.seed-help-bubble[data-modal] [data-part="arrow"] {
  --arrow-background: var(--seed-help-bubble-modal-background);
}

.seed-help-bubble [data-part="content"] {
  z-index: 1000;
  outline: none;

  background: var(--seed-help-bubble-background);

  border-radius: var(--seed-help-bubble-border-radius);
  padding: var(--seed-help-bubble-padding-y) var(--seed-help-bubble-padding-x);
}

.seed-help-bubble [data-part="content"]:not([hidden]) {
  display: flex;
  align-items: flex-start;
}

.seed-help-bubble[data-modal] [data-part="content"] {
  background: var(--seed-help-bubble-modal-background);
}

.seed-help-bubble [data-part="title"] {
  color: var(--seed-help-bubble-color);
}

.seed-help-bubble[data-modal] [data-part="title"] {
  color: var(--seed-help-bubble-modal-color);
}

.seed-help-bubble [data-part="description"] {
  color: var(--seed-help-bubble-color);
}

.seed-help-bubble[data-modal] [data-part="description"] {
  color: var(--seed-help-bubble-modal-color);
}

.seed-help-bubble [data-part="close-trigger"] {
  flex-shrink: 0;
  padding: 2px 0 0 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  color: var(--seed-help-bubble-color);

  width: var(--seed-help-bubble-close-trigger-size);
  height: var(--seed-help-bubble-close-trigger-size);
  margin-left: var(--seed-help-bubble-close-spacing);
}

.seed-help-bubble[data-modal] [data-part="close-trigger"] {
  color: var(--seed-help-bubble-modal-color);
}
