@keyframes Refresher_twink__4bs2ey1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes Refresher_spin__4bs2ey2 {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
    opacity: 0;
  }
}
.Refresher_boxSizing__4bs2ey0 {
  box-sizing: border-box;
}
.Refresher_pull__4bs2ey3 {
  transition: none !important;
  touch-action: pan-x pan-down pinch-zoom !important;
}
.Refresher_refresh__4bs2ey5 svg {
  animation: 300ms Refresher_twink__4bs2ey1 forwards;
}
.Refresher_endRefresh__4bs2ey4 svg {
  animation: 1s Refresher_spin__4bs2ey2 forwards;
}
.Refresher_refreshProgressWrapper__4bs2ey7 {
  height: 0;
  pointer-events: none;
  min-height: 0;
  transition: min-height 0.3s;
  width: 100%;
  overflow: hidden;
}
.Refresher_spinner__4bs2ey8 {
  display: block;
  width: 1.667rem;
  height: 1.667rem;
  margin: 0 auto;
}
.Refresher_refreshProgressInner__4bs2ey9 {
  margin-top: 0.833rem;
}
.Refresher_refreshProgressInner__4bs2ey9 svg {
  display: block;
  width: 1.667rem;
  height: 1.667rem;
  margin: 0 auto;
  box-sizing: border-box;
}
.Refresher_refreshProgressInner__4bs2ey9 svg path {
  transition: stroke 0.3s ease-in;
}