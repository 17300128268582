:root[data-seed] {
  /* BoxButton */
  /* focus indicator */
  --seed-box-button-focus-border: 2px solid var(--seed-scale-color-blue-600);

  /* Color */
  /* variant=Primary */
  --seed-box-button-primary-enabled-background: var(--seed-semantic-color-primary);
  --seed-box-button-primary-enabled-color: var(--seed-semantic-color-on-primary);
  --seed-box-button-primary-hovered-background: var(--seed-semantic-color-primary-hover);
  --seed-box-button-primary-hovered-color: var(--seed-semantic-color-on-primary);
  --seed-box-button-primary-pressed-background: var(--seed-semantic-color-primary-pressed);
  --seed-box-button-primary-pressed-color: var(--seed-semantic-color-on-primary);
  --seed-box-button-primary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-primary-disabled-color: var(--seed-scale-color-gray-500);

  /* variant=PrimaryLow */
  --seed-box-button-primary-low-enabled-background: var(--seed-semantic-color-primary-low);
  --seed-box-button-primary-low-enabled-color: var(--seed-semantic-color-primary);
  --seed-box-button-primary-low-hovered-background: var(--seed-semantic-color-primary-low-hover);
  --seed-box-button-primary-low-hovered-color: var(--seed-semantic-color-primary);
  --seed-box-button-primary-low-pressed-background: var(--seed-semantic-color-primary-low-pressed);
  --seed-box-button-primary-low-pressed-color: var(--seed-semantic-color-primary);
  --seed-box-button-primary-low-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-primary-low-disabled-color: var(--seed-scale-color-gray-500);

  /* variant=Secondary */
  --seed-box-button-secondary-enabled-background: var(--seed-scale-color-gray-100);
  --seed-box-button-secondary-enabled-color: var(--seed-scale-color-gray-900);
  --seed-box-button-secondary-hovered-background: var(--seed-scale-color-gray-300);
  --seed-box-button-secondary-hovered-color: var(--seed-scale-color-gray-900);
  --seed-box-button-secondary-pressed-background: var(--seed-scale-color-gray-300);
  --seed-box-button-secondary-pressed-color: var(--seed-scale-color-gray-900);
  --seed-box-button-secondary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-secondary-disabled-color: var(--seed-scale-color-gray-500);

  /* variant=Danger */
  --seed-box-button-danger-enabled-background: var(--seed-semantic-color-danger);
  --seed-box-button-danger-enabled-color: var(--seed-static-color-static-white);
  --seed-box-button-danger-hovered-background: var(--seed-scale-color-red-400);
  --seed-box-button-danger-hovered-color: var(--seed-static-color-static-white);
  --seed-box-button-danger-pressed-background: var(--seed-scale-color-red-400);
  --seed-box-button-danger-pressed-color: var(--seed-static-color-static-white);
  --seed-box-button-danger-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-danger-disabled-color: var(--seed-scale-color-gray-500);

  /* Layout */
  /* size=Xlarge */
  --seed-box-button-xlarge-height: 52px;
  --seed-box-button-xlarge-padding-x: 20px;
  --seed-box-button-xlarge-border-radius: 6px;
  --seed-box-button-xlarge-prefix-size: 20px;
  --seed-box-button-xlarge-suffix-size: 18px;
  --seed-box-button-xlarge-suffix-bleed: 2px;
  --seed-box-button-xlarge-gap: 4px;

  /* size=Large */
  --seed-box-button-large-height: 48px;
  --seed-box-button-large-padding-x: 20px;
  --seed-box-button-large-border-radius: 6px;
  --seed-box-button-large-prefix-size: 16px;
  --seed-box-button-large-suffix-size: 14px;
  --seed-box-button-large-suffix-bleed: 2px;
  --seed-box-button-large-gap: 4px;

  /* size=Medium */
  --seed-box-button-medium-height: 40px;
  --seed-box-button-medium-padding-x: 16px;
  --seed-box-button-medium-border-radius: 6px;
  --seed-box-button-medium-prefix-size: 16px;
  --seed-box-button-medium-suffix-size: 14px;
  --seed-box-button-medium-suffix-bleed: 2px;
  --seed-box-button-medium-gap: 4px;

  /* size=Small */
  --seed-box-button-small-height: 36px;
  --seed-box-button-small-padding-x: 14px;
  --seed-box-button-small-border-radius: 4px;
  --seed-box-button-small-prefix-size: 16px;
  --seed-box-button-small-suffix-size: 14px;
  --seed-box-button-small-suffix-bleed: 2px;
  --seed-box-button-small-gap: 4px;

  /* size=Xsmall */
  --seed-box-button-xsmall-height: 32px;
  --seed-box-button-xsmall-padding-x: 10px;
  --seed-box-button-xsmall-border-radius: 4px;
  --seed-box-button-xsmall-prefix-size: 14px;
  --seed-box-button-xsmall-suffix-size: 12px;
  --seed-box-button-xsmall-suffix-bleed: 1px;
  --seed-box-button-xsmall-gap: 2px;

  /* CapsuleButton */
  /* focus indicator */
  --seed-capsule-button-focus-border: 2px solid var(--seed-scale-color-blue-600);

  /* Color */
  --seed-capsule-button-primary-low-enabled-background: var(--seed-semantic-color-primary-low);
  --seed-capsule-button-primary-low-enabled-color: var(--seed-semantic-color-primary);
  --seed-capsule-button-primary-low-hovered-background: var(--seed-semantic-color-primary-low-hover);
  --seed-capsule-button-primary-low-hovered-color: var(--seed-semantic-color-primary);
  --seed-capsule-button-primary-low-pressed-background: var(--seed-semantic-color-primary-low-pressed);
  --seed-capsule-button-primary-low-pressed-color: var(--seed-semantic-color-primary);
  --seed-capsule-button-primary-low-disabled-background: var(--seed-scale-color-gray-300);
  --seed-capsule-button-primary-low-disabled-color: var(--seed-scale-color-gray-500);

  --seed-capsule-button-secondary-enabled-background: var(--seed-semantic-color-secondary-low);
  --seed-capsule-button-secondary-enabled-color: var(--seed-scale-color-gray-900);
  --seed-capsule-button-secondary-hovered-background: var(--seed-scale-color-gray-200);
  --seed-capsule-button-secondary-hovered-color: var(--seed-scale-color-gray-900);
  --seed-capsule-button-secondary-pressed-background: var(--seed-scale-color-gray-200);
  --seed-capsule-button-secondary-pressed-color: var(--seed-scale-color-gray-900);
  --seed-capsule-button-secondary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-capsule-button-secondary-disabled-color: var(--seed-scale-color-gray-500);

  /* Layout */
  /* size=Small */
  --seed-capsule-button-small-height: 36px;
  --seed-capsule-button-small-padding-x: 14px;

  /* size=Xsmall */
  --seed-capsule-button-xsmall-height: 32px;
  --seed-capsule-button-xsmall-padding-x: 10px;
  
  /* Radii */
  --seed-capsule-button-border-radius: 9999px;

  /* TextButton */
  /* focus indicator */
  --seed-text-button-focus-border: 2px solid var(--seed-scale-color-blue-600);

  /* Color */
  /* variant=Primary */
  --seed-text-button-primary-color: var(--seed-semantic-color-primary);
  --seed-text-button-primary-hovered-color: var(--seed-semantic-color-primary-hover);
  --seed-text-button-primary-pressed-color: var(--seed-semantic-color-primary-pressed);
  --seed-text-button-primary-disabled-color: var(--seed-scale-color-gray-400);

  /* variant=Secondary */
  --seed-text-button-secondary-color: var(--seed-semantic-color-secondary);
  --seed-text-button-secondary-hovered-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-pressed-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-disabled-color: var(--seed-scale-color-gray-400);

  /* varaint=SecondaryLow */
  --seed-text-button-secondary-low-color: var(--seed-scale-color-gray-600);
  --seed-text-button-secondary-low-hovered-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-low-pressed-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-low-disabled-color: var(--seed-scale-color-gray-400);

  /* varaint=Accent */
  --seed-text-button-accent-color: var(--seed-semantic-color-accent);
  --seed-text-button-accent-hovered-color: var(--seed-scale-color-blue-400);
  --seed-text-button-accent-pressed-color: var(--seed-scale-color-blue-400);
  --seed-text-button-accent-disabled-color: var(--seed-scale-color-gray-400);

  /* varaint=Danger */
  --seed-text-button-danger-color: var(--seed-semantic-color-danger);
  --seed-text-button-danger-hovered-color: var(--seed-scale-color-red-400);
  --seed-text-button-danger-pressed-color: var(--seed-scale-color-red-400);
  --seed-text-button-danger-disabled-color: var(--seed-scale-color-gray-400);


  /* Layout */
  /* size=Large */
  --seed-text-button-large-height: 28px;

  /* size=Medium */
  --seed-text-button-medium-height: 26px;

  /* size=Small */
  --seed-text-button-small-height: 23px;
}
