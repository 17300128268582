:root[data-seed] {
  /* Color */
  --seed-text-field-label-color: var(--seed-scale-color-gray-900);

  /* enabled */
  --seed-text-field-enabled-field-background: var(--seed-semantic-color-paper-default);
  --seed-text-field-enabled-field-border-color: var(--seed-scale-color-gray-400);

  --seed-text-field-enabled-invalid-field-background: var(--seed-semantic-color-danger-low);
  --seed-text-field-enabled-invalid-field-border-color: var(--seed-semantic-color-danger);

  --seed-text-field-enabled-text-color: var(--seed-scale-color-gray-900);
  --seed-text-field-enabled-placeholder-color: var(--seed-scale-color-gray-600);
  --seed-text-field-enabled-description-color: var(--seed-scale-color-gray-600);
  --seed-text-field-enabled-character-count-color: var(--seed-scale-color-gray-900);
  --seed-text-field-enabled-error-message-color: var(--seed-semantic-color-danger);

  /* focused */
  --seed-text-field-focused-field-background: var(--seed-semantic-color-paper-default);
  --seed-text-field-focused-field-border-color: var(--seed-scale-color-gray-900);

  --seed-text-field-focused-invalid-field-background: var(--seed-semantic-color-danger-low);
  --seed-text-field-focused-invalid-field-border-color: var(--seed-semantic-color-danger);

  --seed-text-field-focused-text-color: var(--seed-scale-color-gray-900);
  --seed-text-field-focused-placeholder-color: var(--seed-scale-color-gray-600);
  --seed-text-field-focused-description-color: var(--seed-scale-color-gray-600);
  --seed-text-field-focused-error-message-color: var(--seed-semantic-color-danger);

  /* readonly */
  --seed-text-field-readonly-field-background: var(--seed-scale-color-gray-50);
  --seed-text-field-readonly-field-border-color: var(--seed-scale-color-gray-400);
  --seed-text-field-readonly-text-color: var(--seed-scale-color-gray-900);
  --seed-text-field-readonly-placeholder-color: var(--seed-scale-color-gray-600);
  --seed-text-field-readonly-description-color: var(--seed-scale-color-gray-600);

  /* disabled */
  --seed-text-field-disabled-field-background: var(--seed-scale-color-gray-100);
  --seed-text-field-disabled-field-border-color: var(--seed-scale-color-gray-400);
  --seed-text-field-disabled-text-color: var(--seed-scale-color-gray-400);
  --seed-text-field-disabled-placeholder-color: var(--seed-scale-color-gray-400);
  --seed-text-field-disabled-description-color: var(--seed-scale-color-gray-600);

  /* Layout */
  --seed-text-field-min-width: 280px;
  --seed-text-field-border-radius: 6px;
  --seed-text-field-border-width: 1px;
  
  --seed-text-field-label-margin-end: 4px;
  --seed-text-field-label-margin-bottom: 12px;
  --seed-text-field-description-margin-top: 8px;

  --seed-text-field-prefix-margin-right: 8px;
  --seed-text-field-suffix-margin-left: 8px;

  /* variant=underlined */
  --seed-text-field-underlined-border-radius: 0px;
  --seed-text-field-underlined-border-width: 0 0 1px 0;

  /* size=large */
  --seed-text-field-large-padding-x: 14px;
  --seed-text-field-large-padding-y: 15px;
  --seed-text-field-large-height: 54px;

  /* size=medium */
  --seed-text-field-medium-padding-x: 14px;
  --seed-text-field-medium-padding-y: 15px;
  --seed-text-field-medium-height: 52px;

  /* size=small */
  --seed-text-field-small-padding-x: 12px;
  --seed-text-field-small-padding-y: 11px;
  --seed-text-field-small-height: 42px;

  /* variant=underlined */
  --seed-text-field-underlined-padding-x: 0px;
  --seed-text-field-underlined-large-height: 45px;
  --seed-text-field-underlined-medium-height: 43px;
  --seed-text-field-underlined-small-height: 41px;

  /* Multiline */
  --seed-multiline-text-field-min-height: 95px;
  --seed-multiline-text-field-padding-x: 14px;
  --seed-multiline-text-field-padding-y: 15px;
}
