.CardPlace_button__1gcupwk0 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--seed-scale-color-gray-900);
}
.CardPlace_bizInfoContainer__1gcupwk1 {
  display: flex;
}
.CardPlace_imageContainer__1gcupwk4 {
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 0.375rem;
  outline: 1px solid var(--seed-semantic-color-divider-1);
  outline-offset: -1px;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
          mask-image: -webkit-radial-gradient(white, black);
  position: relative;
  margin-left: 1rem;
}
.CardPlace_imageContainer__1gcupwk4::before {
  content: var(--imageCountVar__1gcupwk2);
  display: var(--displayImageCountVar__1gcupwk3);
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  color: var(--seed-static-color-static-white);
  font-size: 0.6875rem;
  font-weight: 400;
  border-top-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: var(--seed-semantic-color-overlay-dim);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.CardPlace_imageContainer__1gcupwk4 .CardPlace_image__1gcupwk5 {
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 0.375rem;
  object-fit: cover;
}
.CardPlace_image__1gcupwk5[src=""] {
  display: none;
}
.CardPlace_contents__1gcupwk6 {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 0;
}
.CardPlace_titleContainer__1gcupwk7 {
  word-wrap: break-word;
  text-align: left;
}
.CardPlace_title__1gcupwk8 {
  font-size: var(--seed-semantic-typography-subtitle1-bold-font-size);
  font-weight: var(--seed-semantic-typography-subtitle1-bold-font-weight);
  line-height: var(--seed-semantic-typography-subtitle1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle1-bold-letter-spacing);
  word-break: break-all;
  margin-right: 0.25rem;
  vertical-align: middle;
}
.CardPlace_categoryName__1gcupwk9 {
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
  word-break: keep-all;
  color: var(--seed-scale-color-gray-600);
  vertical-align: middle;
}
.CardPlace_body__1gcupwka {
  display: flex;
  flex-direction: column;
}
.CardPlace_bodyContents__1gcupwkb {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  text-align: left;
  margin-top: 0.125rem;
  color: var(--seed-scale-color-gray-600);
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
}
.CardPlace_lineClampWithEllipsis__1gcupwkc {
  display: -webkit-box;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  -webkit-line-clamp: 2;
}
.CardPlace_reviewContainer__1gcupwkd {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.CardPlace_commentContainer__1gcupwke {
  font-size: var(--seed-semantic-typography-body-m2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: var(--seed-semantic-color-paper-contents);
}
.CardPlace_reviewWriter__1gcupwkf {
  font-weight: 700;
}
.CardPlace_reactionContainer__1gcupwkg {
  word-break: keep-all;
}
.CardPlace_reviewAndFollowerTagGroup__1gcupwkh {
  margin-top: 0.25rem;
}
.CardPlace_operationDistanceRegionTagGroup__1gcupwki {
  margin-top: 0.1875rem;
}
.CardPlace_starIcon__1gcupwkj {
  margin-right: 0.125rem;
  display: inline;
}
.CardPlace_badgeContainer__1gcupwkk {
  display: flex;
  margin-top: 0.375rem;
}
.CardPlace_badgeContainer__1gcupwkk .CardPlace_badge__1gcupwkl {
  margin-right: 0.25rem;
}