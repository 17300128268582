.CardCar_container__1t60uv0 {
  display: flex;
  color: var(--seed-scale-color-gray-900);
}
.CardCar_imageContainer__1t60uv1 {
  flex: 0 0 auto;
  width: 6.75rem;
  height: 6.75rem;
  border: 1px solid var(--seed-scale-color-gray-alpha-50);
  background-color: var(--seed-semantic-color-paper-background);
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.375rem;
}
.CardCar_imageContainer__1t60uv1 .CardCar_image__1t60uv2 {
  width: 6.75rem;
  height: 6.75rem;
  object-fit: cover;
  border-radius: 0.375rem;
}
.CardCar_image__1t60uv2[src=""] {
  display: none;
}
.CardCar_contents__1t60uv3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 0;
}
.CardCar_header__1t60uv4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CardCar_title__1t60uv5 {
  font-size: var(--seed-semantic-typography-label2-regular-font-size);
  font-weight: var(--seed-semantic-typography-label2-regular-font-weight);
  line-height: var(--seed-semantic-typography-label2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label2-regular-letter-spacing);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-right: 1.5rem;
  text-align: left;
}
.CardCar_body__1t60uv6 {
  display: flex;
  flex-direction: column;
  margin-top: 0.3125rem;
}
.CardCar_carSpecContainer__1t60uv7 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CardCar_badgeContainer__1t60uv8 {
  display: flex;
  flex-direction: row;
  margin-top: 0.375rem;
}
.CardCar_badge__1t60uv9 {
  margin-right: 0.25rem;
}
.CardCar_badge__1t60uv9:last-child {
  margin-right: 0;
}
.CardCar_articleStatusContainer__1t60uva {
  margin-top: 0.375rem;
}
.CardCar_footer__1t60uvb {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;
}
.CardCar_iconChatting__1t60uvc {
  fill: var(--seed-scale-color-gray-600);
}
.CardCar_iconHeart__1t60uvd {
  fill: var(--seed-scale-color-gray-600);
}
.CardCar_caption__1t60uve {
  color: var(--seed-scale-color-gray-700);
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
  margin-right: 0.3125rem;
  line-height: 100%;
}
.CardCar_caption__1t60uve:last-child {
  margin-right: 0;
}
.CardCar_captionIcon__1t60uvf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.219rem;
  color: var(--seed-scale-color-gray-600);
}