:root[data-seed] {
  /* Color */
  --seed-help-bubble-background: var(--seed-scale-color-gray-900);
  --seed-help-bubble-color: var(--seed-scale-color-gray-00);

  --seed-help-bubble-modal-background: var(--seed-static-color-static-white);
  --seed-help-bubble-modal-color: var(--seed-static-color-static-black);
  
  --seed-help-bubble-backdrop-background: var(--seed-scale-color-gray-alpha-500);

  /* Layout */
  --seed-help-bubble-border-radius: 6px;
  --seed-help-bubble-padding-x: 12px;
  --seed-help-bubble-padding-y: 8px;

  --seed-help-bubble-arrow-size: 10px;
  --seed-help-bubble-close-trigger-size: 16px;
  --seed-help-bubble-close-spacing: 4px;
}
