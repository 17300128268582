.RecentSearch_container__1rkgj4m0 {
  display: flex;
  flex-direction: column;
}
.RecentSearch_title__1rkgj4m1 {
  font-size: var(--seed-semantic-typography-subtitle2-bold-font-size);
  font-weight: var(--seed-semantic-typography-subtitle2-bold-font-weight);
  line-height: var(--seed-semantic-typography-subtitle2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle2-bold-letter-spacing);
  color: var(--seed-scale-color-gray-900);
  margin-top: 0;
  margin-bottom: 0;
  margin-right: auto;
}
.RecentSearch_removeAll__1rkgj4m2 {
  font-size: var(--seed-semantic-typography-label3-regular-font-size);
  font-weight: var(--seed-semantic-typography-label3-regular-font-weight);
  line-height: var(--seed-semantic-typography-label3-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label3-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
  padding: 0.125rem 0.375rem;
  cursor: pointer;
}
.RecentSearch_removeAll__1rkgj4m2:active {
  background-color: var(--seed-scale-color-gray-200);
  transition: background-color 0s;
}
.RecentSearch_header__1rkgj4m3 {
  display: flex;
  align-items: center;
  margin: 0 0.625rem 0 1rem;
  padding: 0.625rem 0;
}
.RecentSearch_body__1rkgj4m4 {
  display: flex;
  flex-direction: column;
}