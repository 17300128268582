.Tag_tag_color_gray600__cjyyfb0 {
  color: var(--seed-scale-color-gray-600);
}
.Tag_tag_color_gray700__cjyyfb1 {
  color: var(--seed-scale-color-gray-700);
}
.Tag_tag_color_gray900__cjyyfb2 {
  color: var(--seed-scale-color-gray-900);
}
.Tag_tag_color_green800__cjyyfb3 {
  color: var(--seed-scale-color-green-800);
}
.Tag_tag_color_red950__cjyyfb4 {
  color: var(--seed-scale-color-red-950);
}
.Tag_tag_color_primary__cjyyfb5 {
  color: var(--seed-semantic-color-primary);
}
.Tag_tag_size_small__cjyyfb6 {
  font-size: var(--seed-semantic-typography-caption2-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption2-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption2-regular-letter-spacing);
}
.Tag_tag_size_medium__cjyyfb7 {
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
}
.Tag_tag_size_large__cjyyfb8 {
  font-size: var(--seed-semantic-typography-label3-regular-font-size);
  font-weight: var(--seed-semantic-typography-label3-regular-font-weight);
  line-height: var(--seed-semantic-typography-label3-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label3-regular-letter-spacing);
}
.Tag_tag_bold_true__cjyyfb9 {
  font-weight: var(--seed-static-font-weight-bold);
}
.Tag_icon__cjyyfba {
  display: inline-flex;
  vertical-align: middle;
}
.Tag_leadingIcon__cjyyfbb {
  display: inline-flex;
  vertical-align: middle;
}