html {
  cursor: default;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  user-select: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

#root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Remove default padding and list-style */
ul,
ol {
  padding: 0;
  list-style: none;
}

/* Remove default margin */
ul,
ol,
li {
  margin: 0;
}

.seed-snackbar {
  min-height: 41px;
  padding-top: 0;
  padding-bottom: 0;
}
