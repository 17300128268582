.AutoCompleteKeyword_Container__t3w8uo0 {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 300ms;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
}
.AutoCompleteKeyword_Container__t3w8uo0:active {
  background-color: var(--seed-scale-color-gray-100);
  transition: background-color 0s;
}
.AutoCompleteKeyword_SearchIcon__t3w8uo1 {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
  color: var(--seed-scale-color-gray-900);
}
.AutoCompleteKeyword_LabelContainer__t3w8uo2 {
  display: flex;
  align-items: baseline;
}
.AutoCompleteKeyword_AutoCompletionLabel__t3w8uo3 {
  padding: 0.594rem 0;
  white-space: nowrap;
  font-size: 0.875rem;
  color: var(--seed-scale-color-gray-900);
}
.AutoCompleteKeyword_AutoCompletionCategory__t3w8uo4 {
  font-size: 0.813rem;
  margin-left: 0.5rem;
  color: var(--seed-scale-color-gray-600);
}
.AutoCompleteKeyword_Highlight__t3w8uo5 {
  background-color: inherit;
  text-decoration: none;
  font-style: normal;
  color: var(--seed-scale-color-carrot-500);
}