.TagDivider_dotDivider__1p3l2440 {
  font-weight: var(--seed-static-font-weight-bold);
  font-size: 0.625rem;
  display: inline-flex;
  vertical-align: middle;
}
.TagDivider_dotDivider__1p3l2440::after {
  content: "\B7";
}
.TagDivider_dotDivider_size_small__1p3l2441 {
  margin: 0 0.2188rem;
}
.TagDivider_dotDivider_size_medium__1p3l2442 {
  margin: 0 0.25rem;
}
.TagDivider_dotDivider_color_gray500__1p3l2443 {
  color: var(--seed-scale-color-gray-500);
}
.TagDivider_dotDivider_color_gray600__1p3l2444 {
  color: var(--seed-scale-color-gray-600);
}