:root[data-seed] {
  --seed-spinner-track-gray-fill: var(--seed-scale-color-gray-alpha-50);
  --seed-spinner-track-indicator-gray-color: var(--seed-scale-color-gray-400);

  --seed-spinner-track-white-fill: var(--seed-static-color-static-white-alpha-200);
  --seed-spinner-track-indicator-white-color: var(--seed-static-color-static-white);
  
  --seed-spinner-track-primary-fill: var(--seed-semantic-color-primary-low);
  --seed-spinner-track-indicator-primary-color: var(--seed-semantic-color-primary);

  --seed-spinner-white-color: var(--seed-static-color-static-white);
  --seed-spinner-primary-color: var(--seed-semantic-color-primary);

  --seed-spinner-medium-size: 40px;
  --seed-spinner-small-size: 24px;

  --seed-spinner-duration: 1.2s;
  --seed-spinner-timing-function: cubic-bezier(0.35, 0.25, 0.65, 0.75);
  --seed-spinner-head-dash-timing-function: cubic-bezier(0.35, 0, 0.65, 1);
  --seed-spinner-tail-dash-timing-function: cubic-bezier(0.35, 0, 0.65, 0.6);
}
