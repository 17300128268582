@keyframes HideAnimation_disappearKeyFrame__1pgxj8l1 {
  from {
    max-height: 35rem;
  }
  to {
    padding: 0;
    max-height: 0;
  }
}
.HideAnimation_root__1pgxj8l0 {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.HideAnimation_hide__1pgxj8l2 {
  opacity: 0;
  animation: 0.8s forwards HideAnimation_disappearKeyFrame__1pgxj8l1;
  pointer-events: none;
}