:root[data-seed] {
    --seed-snackbar-region-padding-x: 8px;

    --seed-snackbar-background: var(--seed-scale-color-gray-900);
    --seed-snackbar-color: var(--seed-scale-color-gray-00);
    --seed-snackbar-action-color: var(--seed-semantic-color-primary);

    --seed-snackbar-success-icon-color: var(--seed-semantic-color-success);
    --seed-snackbar-warning-icon-color: var(--seed-semantic-color-danger);

    --seed-snackbar-max-width: 360px;
    --seed-snackbar-padding: 10px 16px;
    --seed-snackbar-border-radius: 6px;

    --seed-snackbar-action-padding: 3px 4px;
    --seed-snackbar-action-margin: 8px;

    --seed-snackbar-icon-size: 20px;
    --seed-snackbar-icon-margin-end: 8px;
}
