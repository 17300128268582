.TagEllipsis_tag__ne5iyi0 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.TagEllipsis_tag_size_small__ne5iyi1 {
  font-size: var(--seed-semantic-typography-caption2-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption2-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption2-regular-letter-spacing);
}
.TagEllipsis_tag_size_medium__ne5iyi2 {
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
}
.TagEllipsis_tag_size_large__ne5iyi3 {
  font-size: var(--seed-semantic-typography-label3-regular-font-size);
  font-weight: var(--seed-semantic-typography-label3-regular-font-weight);
  line-height: var(--seed-semantic-typography-label3-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label3-regular-letter-spacing);
}
.TagEllipsis_tag_bold_true__ne5iyi4 {
  font-weight: var(--seed-static-font-weight-bold);
}
.TagEllipsis_tag_color_gray600__ne5iyi5 {
  color: var(--seed-scale-color-gray-600);
}
.TagEllipsis_tag_color_gray900__ne5iyi6 {
  color: var(--seed-scale-color-gray-900);
}
.TagEllipsis_tag_color_primary__ne5iyi7 {
  color: var(--seed-semantic-color-primary);
}