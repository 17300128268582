/* TextField */
/* root */
.seed-text-field[data-part="root"] {
  display: flex;
  flex-direction: column;

  min-width: var(--seed-text-field-min-width);
}

/* head */
.seed-text-field [data-part="head"] {
  margin-bottom: var(--seed-text-field-label-margin-bottom);
}

/* label */
.seed-text-field [data-part="label"] {
  color: var(--seed-text-field-label-color);
  margin-inline-end: var(--seed-text-field-label-margin-end);
}

/* indicator */
.seed-text-field [data-part="indicator"] {
  color: var(--seed-text-field-label-color);
}

/* field */
.seed-text-field [data-part="field"] {
  display: flex;
}

.seed-text-field [data-part="field"] {
  border-radius: var(--seed-text-field-border-radius);
  border-width: var(--seed-text-field-border-width);
  border-style: solid;
}

.seed-text-field[data-size="large"] [data-part="field"] {
  height: var(--seed-text-field-large-height);
  padding-left: var(--seed-text-field-large-padding-x);
  padding-right: var(--seed-text-field-large-padding-x);
}

.seed-text-field[data-size="medium"] [data-part="field"] {
  height: var(--seed-text-field-medium-height);
  padding-left: var(--seed-text-field-medium-padding-x);
  padding-right: var(--seed-text-field-medium-padding-x);
}

.seed-text-field[data-size="small"] [data-part="field"] {
  height: var(--seed-text-field-small-height);
  padding-left: var(--seed-text-field-small-padding-x);
  padding-right: var(--seed-text-field-small-padding-x);
}

.seed-text-field[data-variant="underlined"] [data-part="field"] {
  border-width: var(--seed-text-field-underlined-border-width);
  border-radius: var(--seed-text-field-underlined-border-radius);
}

.seed-text-field[data-variant="underlined"][data-size="large"]
  [data-part="field"] {
  height: var(--seed-text-field-underlined-large-height);
  padding-left: var(--seed-text-field-underlined-padding-x);
  padding-right: var(--seed-text-field-underlined-padding-x);
}

.seed-text-field[data-variant="underlined"][data-size="medium"]
  [data-part="field"] {
  height: var(--seed-text-field-underlined-medium-height);
  padding-left: var(--seed-text-field-underlined-padding-x);
  padding-right: var(--seed-text-field-underlined-padding-x);
}

.seed-text-field[data-variant="underlined"][data-size="small"]
  [data-part="field"] {
  height: var(--seed-text-field-underlined-small-height);
  padding-left: var(--seed-text-field-underlined-padding-x);
  padding-right: var(--seed-text-field-underlined-padding-x);
}

/* input */
.seed-text-field [data-part="input"] {
  padding: 0;
  margin: 0;
  min-width: 0;
  box-sizing: border-box;
  background: none;
  border: none;
  flex-grow: 1;
}

.seed-text-field [data-part="input"]:focus {
  outline: none;
}

/* prefix */
.seed-text-field [data-part="prefix"] {
  display: flex;
  align-items: center;
  white-space: nowrap;

  margin-right: var(--seed-text-field-prefix-margin-right);
}

/* suffix */
.seed-text-field [data-part="suffix"] {
  display: flex;
  align-items: center;
  white-space: nowrap;

  margin-left: var(--seed-text-field-suffix-margin-left);
}

/* foot */
.seed-text-field [data-part="foot"] {
  display: flex;
  justify-content: space-between;

  margin-block-start: var(--seed-text-field-description-margin-top);
}

/* enabled */
.seed-text-field [data-part="field"] {
  background: var(--seed-text-field-enabled-field-background);
  border-color: var(--seed-text-field-enabled-field-border-color);
}

.seed-text-field [data-part="input"] {
  color: var(--seed-text-field-enabled-text-color);
}

.seed-text-field [data-part="input"]::placeholder {
  color: var(--seed-text-field-enabled-placeholder-color);
}

.seed-text-field [data-part="description"] {
  display: flex;
  align-items: flex-start;

  color: var(--seed-text-field-enabled-description-color);
}

.seed-text-field [data-part="count-container"] {
  text-align: end;
}

.seed-text-field [data-part="max-count"] {
  color: var(--seed-text-field-enabled-description-color);
}

.seed-text-field [data-part="character-count"] {
  color: var(--seed-text-field-enabled-character-count-color);
}

/* enabled, invalid */
.seed-text-field[data-invalid] [data-part="field"] {
  border-color: var(--seed-text-field-enabled-invalid-field-border-color);
}
.seed-text-field[data-invalid][data-variant="outlined"] [data-part="field"] {
  background: var(--seed-text-field-enabled-invalid-field-background);
}

.seed-text-field [data-part="error-message"] {
  display: flex;
  align-items: flex-start;

  color: var(--seed-text-field-enabled-error-message-color);
}

/* focused */
.seed-text-field[data-focus] [data-part="field"] {
  border-color: var(--seed-text-field-focused-field-border-color);
}
.seed-text-field[data-focus][data-variant="outlined"] [data-part="field"] {
  background: var(--seed-text-field-focused-field-background);
}

.seed-text-field[data-focus] [data-part="description"] {
  color: var(--seed-text-field-focused-description-color);
}

/* focused, invalid */
.seed-text-field[data-focus][data-invalid] [data-part="field"] {
  background: var(--seed-text-field-focused-invalid-field-background);
  border-color: var(--seed-text-field-focused-invalid-field-border-color);
}

.seed-text-field[data-focus] [data-part="error-message"] {
  color: var(--seed-text-field-focused-error-message-color);
}

/* readonly */
.seed-text-field[data-readonly] [data-part="field"] {
  border-color: var(--seed-text-field-readonly-field-border-color);
}
.seed-text-field[data-readonly][data-variant="outlined"] [data-part="field"] {
  background: var(--seed-text-field-readonly-field-background);
}

.seed-text-field[data-readonly] [data-part="description"] {
  color: var(--seed-text-field-readonly-description-color);
}

/* disabled */
.seed-text-field[data-disabled] [data-part="field"] {
  border-color: var(--seed-text-field-disabled-field-border-color);
}
.seed-text-field[data-disabled][data-variant="outlined"] [data-part="field"] {
  background: var(--seed-text-field-disabled-field-background);
}

.seed-text-field[data-disabled] [data-part="input"] {
  color: var(--seed-text-field-disabled-text-color);
}

.seed-text-field[data-disabled] [data-part="input"]::placeholder {
  color: var(--seed-text-field-disabled-placeholder-color);
}

.seed-text-field[data-disabled] [data-part="description"] {
  color: var(--seed-text-field-disabled-description-color);
}

.seed-text-field[data-disabled] [data-part="max-count"] {
  color: var(--seed-text-field-disabled-description-color);
}

.seed-text-field[data-disabled] [data-part="character-count"] {
  color: var(--seed-text-field-disabled-description-color);
}

/* icons */
.seed-text-field [data-part="error-message-icon"] {
  width: 1em;
  /* line-height fix */
  /* TODO: remove hard-coded 1.35, get multiplier from variable */
  height: calc(1em * 1.35);
  margin-inline-end: 4px;
  flex-shrink: 0;
}

/* MultilineTextField */
/* root */
.seed-multiline-text-field[data-part="root"] {
  display: flex;
  flex-direction: column;

  min-width: var(--seed-text-field-min-width);
}

/* head */
.seed-multiline-text-field [data-part="head"] {
  margin-bottom: var(--seed-text-field-label-margin-bottom);
}

/* label */
.seed-multiline-text-field [data-part="label"] {
  color: var(--seed-text-field-label-color);
  margin-inline-end: var(--seed-text-field-label-margin-end);
}

/* field */
.seed-multiline-text-field [data-part="field"] {
  display: flex;
  flex: 1 1 auto;

  border-radius: var(--seed-text-field-border-radius);
  border-width: var(--seed-text-field-border-width);
  border-style: solid;
}

/* input */
.seed-multiline-text-field [data-part="input"] {
  height: auto;
  box-sizing: border-box;
  background: none;
  border: none;
  resize: none;
  overflow: auto;
  width: 100%;

  background: none;

  min-height: var(--seed-multiline-text-field-min-height);
  padding: var(--seed-multiline-text-field-padding-y)
    var(--seed-multiline-text-field-padding-x);
}

.seed-multiline-text-field [data-part="input"]:focus {
  outline: none;
}

/* character count */
.seed-multiline-text-field [data-part="character-count"] {
  text-align: end;
}

/* foot */
.seed-multiline-text-field [data-part="foot"] {
  display: flex;
  justify-content: space-between;

  margin-block-start: var(--seed-text-field-description-margin-top);
}

/* enabled */
.seed-multiline-text-field [data-part="field"] {
  background: var(--seed-text-field-enabled-field-background);
  border-color: var(--seed-text-field-enabled-field-border-color);
}

.seed-multiline-text-field [data-part="input"] {
  color: var(--seed-text-field-enabled-text-color);
}

.seed-multiline-text-field [data-part="input"]::placeholder {
  color: var(--seed-text-field-enabled-placeholder-color);
}

.seed-multiline-text-field [data-part="description"] {
  display: flex;
  align-items: flex-start;

  color: var(--seed-text-field-enabled-description-color);
}

.seed-multiline-text-field [data-part="max-count"] {
  color: var(--seed-text-field-enabled-description-color);
}

.seed-multiline-text-field [data-part="character-count"] {
  color: var(--seed-text-field-enabled-character-count-color);
}

/* enabled, invalid */
.seed-multiline-text-field[data-invalid] [data-part="field"] {
  background: var(--seed-text-field-enabled-invalid-field-background);
  border-color: var(--seed-text-field-enabled-invalid-field-border-color);
}

.seed-multiline-text-field [data-part="error-message"] {
  display: flex;
  align-items: flex-start;

  color: var(--seed-text-field-enabled-error-message-color);
}

/* focused */
.seed-multiline-text-field[data-focus] [data-part="field"] {
  background: var(--seed-text-field-focused-field-background);
  border-color: var(--seed-text-field-focused-field-border-color);
}

.seed-multiline-text-field[data-focus] [data-part="description"] {
  color: var(--seed-text-field-focused-description-color);
}

/* focused, invalid */
.seed-multiline-text-field[data-focus][data-invalid] [data-part="field"] {
  background: var(--seed-text-field-focused-invalid-field-background);
  border-color: var(--seed-text-field-focused-invalid-field-border-color);
}

.seed-multiline-text-field[data-focus] [data-part="error-message"] {
  color: var(--seed-text-field-focused-error-message-color);
}

/* readonly */
.seed-multiline-text-field[data-readonly] [data-part="field"] {
  background: var(--seed-text-field-readonly-field-background);
  border-color: var(--seed-text-field-readonly-field-border-color);
}

.seed-multiline-text-field[data-readonly] [data-part="description"] {
  color: var(--seed-text-field-readonly-description-color);
}

/* disabled */
.seed-multiline-text-field[data-disabled] [data-part="field"] {
  background: var(--seed-text-field-disabled-field-background);
  border-color: var(--seed-text-field-disabled-field-border-color);
}

.seed-multiline-text-field[data-disabled] [data-part="input"] {
  color: var(--seed-text-field-disabled-text-color);
}

.seed-multiline-text-field[data-disabled] [data-part="input"]::placeholder {
  color: var(--seed-text-field-disabled-placeholder-color);
}

.seed-multiline-text-field[data-disabled] [data-part="description"] {
  color: var(--seed-text-field-disabled-description-color);
}

.seed-multiline-text-field[data-disabled] [data-part="max-count"] {
  color: var(--seed-text-field-disabled-description-color);
}

.seed-multiline-text-field[data-disabled] [data-part="character-count"] {
  color: var(--seed-text-field-disabled-description-color);
}

/* icons */
.seed-multiline-text-field [data-part="error-message-icon"] {
  width: 1em;
  /* line-height fix */
  /* TODO: remove hard-coded 1.35, get multiplier from variable */
  height: calc(1em * 1.35);
  margin-inline-end: 4px;
  flex-shrink: 0;
}
