.TouchableHighlight_container__87xrx50 {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 300ms;
  outline: none;
  text-decoration: none;
}
.TouchableHighlight_container__87xrx50:active:has(button:active, [role="button"]:active) {
  transition: none;
}
.TouchableHighlight_container__87xrx50:active:not(:has(button:active, [role="button"]:active)) {
  background-color: var(--seed-semantic-color-gray-pressed);
  transition: background-color 0s;
}