.HotKeyword_Container__5ayul60 {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  box-shadow: inset 0 0 0 1px var(--seed-semantic-color-divider-2);
  border-radius: 10rem;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 300ms;
  text-decoration: none;
  outline: none;
  margin-right: 0.5rem;
  cursor: pointer;
}
.HotKeyword_Container__5ayul60:active {
  background-color: var(--seed-scale-color-gray-100);
  transition: background-color 0s;
}
.HotKeyword_Container__5ayul60:first-child {
  margin-left: 1rem;
}
.HotKeyword_Container__5ayul60:last-child {
  margin-right: 1rem;
}
.HotKeyword_Keyword__5ayul61 {
  color: var(--seed-scale-color-gray-900);
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
}