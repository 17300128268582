.RecentKeyword_root__1kxa2b60 {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.RecentKeyword_root__1kxa2b60:active:has(button:active) {
  transition: none;
}
.RecentKeyword_root__1kxa2b60:active:not(:has(button:active)) {
  background-color: var(--seed-scale-color-gray-100);
  transition: background-color 0s;
}
.RecentKeyword_keywordContainer__1kxa2b61 {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin: 0.5rem 1rem;
}
.RecentKeyword_keywordWithIconContainer__1kxa2b62 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.RecentKeyword_keyword__1kxa2b63 {
  flex: 1;
  margin-left: 1rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: var(--seed-semantic-typography-body-m1-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m1-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m1-regular-letter-spacing);
  color: var(--seed-scale-color-gray-900);
}
.RecentKeyword_removeIconButton__1kxa2b64 {
  height: 1.75rem;
  width: 1.75rem;
  margin-left: auto;
  padding: 0.375rem;
  overflow: visible;
  border: none;
  cursor: pointer;
  background: none;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}