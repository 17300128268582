.CardCommunityItem_container__1sp2000 {
  padding: 1rem 1rem 0 1rem;
}
.CardCommunityItem_container__1sp2000 > :first-child {
  padding-bottom: 1rem;
}
.CardCommunityItem_container__1sp2000[data-hide-border="false"] > :first-child {
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);
}
.CardCommunityItem_container__1sp2000[data-hide-border="true"] > :first-child {
  border-bottom: 0;
}
.CardCommunityItem_container__1sp2000:is(li):last-child > :first-child {
  border-bottom: 0;
}