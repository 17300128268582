.PullToRefresh_boxSizing__vv8bsy0 {
  box-sizing: border-box;
}
.PullToRefresh_pullToRefreshBase__vv8bsy1 {
  height: 100%;
  overflow: hidden;
}
.PullToRefresh_pullToRefreshScrollContainer__vv8bsy2 {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: static;
  background-color: var(--seed-semantic-color-paper-default);
  overscroll-behavior-y: none;
  display: flex;
  flex-direction: column;
}