.HotKeywords_Container__1e6fgnp0 {
  display: flex;
  flex-direction: column;
}
.HotKeywords_Header__1e6fgnp1 {
  display: flex;
  align-items: center;
  margin: 0.625rem 1rem;
}
.HotKeywords_Title__1e6fgnp2 {
  font-size: var(--seed-semantic-typography-subtitle2-bold-font-size);
  font-weight: var(--seed-semantic-typography-subtitle2-bold-font-weight);
  line-height: var(--seed-semantic-typography-subtitle2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle2-bold-letter-spacing);
  color: var(--seed-scale-color-gray-900);
  margin: 0;
}
.HotKeywords_Body__1e6fgnp3 {
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  padding: 0;
}
.HotKeywords_Body__1e6fgnp3::-webkit-scrollbar {
  display: none;
}
.HotKeywords_KeywordBold__1e6fgnp4 {
  font-weight: bold;
}