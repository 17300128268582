.Highlight_highlight__1ub8ejm0 > em {
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
}
.Highlight_highlight__1ub8ejm0 > mark {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
}