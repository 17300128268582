.seed-spinner {
  display: inline-block;
  animation: var(--seed-spinner-duration) var(--seed-spinner-timing-function) 0s
    infinite normal none running;
  animation-name: rotate;

  position: relative;
}

.seed-spinner[data-size="medium"] {
  width: var(--seed-spinner-medium-size);
  height: var(--seed-spinner-medium-size);
}

.seed-spinner[data-size="small"] {
  width: var(--seed-spinner-small-size);
  height: var(--seed-spinner-small-size);
}

.seed-spinner [data-part="track"] {
  position: absolute;
  inset: 0;
  
  width: 100%;
  height: 100%;
}

.seed-spinner[data-variant="gray"] [data-part="track"] {
  fill: var(--seed-spinner-track-gray-fill);
}
.seed-spinner[data-variant="primary"] [data-part="track"] {
  fill: var(--seed-spinner-track-primary-fill);
}
.seed-spinner[data-variant="white"] [data-part="track"] {
  fill: var(--seed-spinner-track-white-fill);
}

.seed-spinner [data-part="indicator"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
}

.seed-spinner[data-variant="gray"] [data-part="indicator"] {
  color: var(--seed-spinner-track-indicator-gray-color);
}
.seed-spinner[data-variant="primary"] [data-part="indicator"] {
  color: var(--seed-spinner-track-indicator-primary-color);
}
.seed-spinner[data-variant="white"] [data-part="indicator"] {
  color: var(--seed-spinner-track-indicator-white-color);
}

/* 전체길이 110 */
.seed-spinner [data-part="indicator-path"] {
  stroke: currentColor;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 5.2px;
  
  rotate: -90deg;
  transform-origin: center;
  transform-box: fill-box;
  
  animation: 
    head-dash var(--seed-spinner-duration) var(--seed-spinner-head-dash-timing-function) 0s infinite normal none running,
    tail-dash var(--seed-spinner-duration) var(--seed-spinner-tail-dash-timing-function) 0s infinite normal none running;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes head-dash {
  /* 0s */
  0% {
    stroke-dasharray: 1, 200;
  }
  /* 0.9s */
  75% {
    stroke-dasharray: 112, 200;
  }
  /* 1.2s */
  100% {
    /* 111의 100% = 112, (1은 0%의 offset과 일치시키기 위함) */
    stroke-dasharray: 112, 200;
  }
}

@keyframes tail-dash {
  /* 0s */
  0% {
    stroke-dashoffset: 0;
  }
  /* 0.4s */
  33.3% {
    stroke-dashoffset: 0;
  }
  /* 1.2s */
  100% {
    /* 111의 99% = 110 */
    stroke-dashoffset: -110;
  }
}
